import React from 'react';

// Themes
import CssBaseline from '@mui/material/CssBaseline';

// Routes
import { Outlet } from 'react-router-dom';

// Pages
import AppBar from './AppBar'

function Home() {
    return (
	<div>
            <CssBaseline />
	    <AppBar />
	    <Outlet />
	</div>
    );
}

export default Home;
