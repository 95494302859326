import React from 'react';

// Theme
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Routes
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// Pages
import Home from './Home';
import Logo from './Logo';
import MTGLists from './MTGLists';
import MTGTradeList from './MTGTradeList';
import MTGWishList from './MTGWishList';

// Components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function App() {
    // Get system preferences for 'light' vs 'dark' mode
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    // Memoize the theme
    const theme = React.useMemo(
	() =>
	createTheme({
	    palette: {
		mode: prefersDarkMode ? 'dark' : 'light',
		primary: {
		    main: '#673ab7',
		},
		secondary: {
		    main: '#bdbdbd',
		},
	    },
	}),
	[prefersDarkMode]
    );

    return (
	<ThemeProvider theme={theme}>
	    <BrowserRouter>
		<Routes>
		    <Route path='/' element={<Home />} >
			<Route index element={<Logo />} />
			<Route path='/mtg-lists' element={<MTGLists />} />
			<Route path='/mtg-trade-list' element={<MTGTradeList/>} />
			<Route path='/mtg-wish-list' element={<MTGWishList/>} />
			<Route path="*" element={<NoMatch />} />
		    </Route>
		</Routes>
		</BrowserRouter>
	</ThemeProvider>
	);
}

function NoMatch() {
	return (
	<Box
		sx={{
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
		backgroundColor: 'mode',
		}}
	>
		<Typography variant="h1">
		<Link to="/" style={{ textDecoration: 'none' }}>
			404
		</Link>
		</Typography>
	</Box>
	);
}

export default App;
