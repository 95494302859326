import * as React from 'react';
import { NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const pages = [
    {
	name: 'Magic: The Gathering Lists',
	url: '/mtg-lists'
    }
];
const settings = ['Profile'];

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
	setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
	setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
	setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
	setAnchorElUser(null);
    };

    return (
	<AppBar position="static">
	    <Container maxWidth="xl">
            <Toolbar disableGutters>
            <HomeIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
        variant="h6"
        noWrap
        component="a"
        href="/"
        sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
        }}
        >
        MTG
        </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
        >
            <MenuIcon />
            </IconButton>
            <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
            display: { xs: 'block', md: 'none' },
        }}
            >
            {pages.map((page) => (
                <MenuItem component={NavLink} to={page.url} key={page.url} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
            ))}
        </Menu>
            </Box>
            <HomeIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
        variant="h5"
        noWrap
        component="a"
        href=""
        sx={{
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
        }}
        >
        MTG
        </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        {pages.map((page) => (
            <Button
		component={NavLink}
		to={page.url}
		key={page.url}
		onClick={handleCloseNavMenu}
		sx={{ my: 2, color: 'white', display: 'block' }}
	    >
                {page.name}
            </Button>
        ))}
        </Box>

            <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
		<Avatar>
		    <PersonOutlineIcon />
		</Avatar>
        </IconButton>
            </Tooltip>
            <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
            >
            {settings.map((setting) => (
                <MenuItem component={NavLink} to={setting} key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
            ))}
        </Menu>
            </Box>
            </Toolbar>
	    </Container>
	    </AppBar>
    );
};
export default ResponsiveAppBar;
