import React from "react";
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  margin: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const cards = [
    {
	image: 'static/images/TradeListQRCode.png',
	title: 'David\'s Trade List',
	description: 'List of cards that David is looking to trade away',
	url: '/mtg-trade-list'
    },
    {
	image: 'static/images/WishListQRCode.png',
	title: 'David\'s Wish List',
	description: 'List of cards that David is looking to trade for',
	url: '/mtg-wish-list'
    }
]

const MTGLists = () => {
    return (
	<Box sx={{ flexGrow: 1 }}>
	    <Grid container spacing={0}>
		{cards.map((card) => (
		<Grid item xs>
		    <Item>
			<Card>
			    <CardActionArea component={Link} to={card.url}>
				<CardMedia
				    component="img"
				    image={process.env.PUBLIC_URL + card.image}
				    alt="Trades"
				/>
				<CardContent>
				    <Typography gutterBottom variant="h5" component="div">
					{card.title}
				    </Typography>
				    <Typography variant="body2" color="text.secondary">
					{card.description}
				    </Typography>
				</CardContent>
			    </CardActionArea>
			</Card>
		    </Item>
		</Grid>
		))}
	    </Grid>
	</Box>
    );
}

export default MTGLists;
