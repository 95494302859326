import React from "react";
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  margin: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const cards = [
    {
	url: '/mtg-wish-list-mythics',
	alt: 'Mythics',
	image: 'static/images/Mythic.png',
	title: 'David\'s Wish List (Mythics)',
	description: 'List of Mythics that David is looking to trade for.'
    },
    {
	url: '/mtg-wish-list-rares',
	alt: 'Rares',
	image: 'static/images/Rare.png',
	title: 'David\'s Wish List (Rares)',
	description: 'List of Rares that David is looking to trade for.'
    },
    {
	url: '/mtg-wish-list-uncommons',
	alt: 'Uncommons',
	image: 'static/images/Uncommon.png',
	title: 'David\'s Wish List (Uncommons)',
	description: 'List of Uncommons that David is looking to trade for.'
    },
    {
	url: '/mtg-wish-list-commons',
	alt: 'Commons',
	image: 'static/images/Common.png',
	title: 'David\'s Wish List (Commons)',
	description: 'List of Commons that David is looking to trade for.'
    }
]

const MTGWishList = () => {
    return (
	<Grid container spacing={0} justifyContent="center" alignItems="center">
	    {cards.map((card) => (
		<Grid item xs={6} md={3}>
		    <Item>
			<Card>
			    <CardActionArea component={Link} to={card.url} target="_blank">
				<CardMedia
				    component="img"
				    height="400"
				    image={process.env.PUBLIC_URL + card.image}
				    alt={card.alt}
				/>
				<CardContent>
				    <Typography gutterBottom variant="h5" component="div">
					{card.title}
				    </Typography>
				    <Typography variant="body2" color="text.secondary">
					{card.description}
				    </Typography>
				</CardContent>
			    </CardActionArea>
			</Card>
		    </Item>
		</Grid>
	    ))}
	</Grid>
    );
};

export default MTGWishList;
